import defaults from "defaults";

export default window.config = defaults(window.config, {
	env: 'local',
	urls: {
		api: 'https://api-int.sorted.com/pro-ui',
		auth: 'https://aks-feature.sorted.com/auth',
		shipUi: "https://shipments-ui.local",
		editorUi: "https://portal.local/modules/editor",
		swaggerUi: "https://swagger.local",
	},
	gaPropertyId: 'UA-174047108-2'
});
